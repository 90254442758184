import { render, staticRenderFns } from "./collect.vue?vue&type=template&id=64f15792&scoped=true"
import script from "./collect.vue?vue&type=script&lang=js"
export * from "./collect.vue?vue&type=script&lang=js"
import style0 from "./collect.vue?vue&type=style&index=0&id=64f15792&prod&rel=stylesheet%2Fscss&lang=scss&scoped=true"
import style1 from "./collect.vue?vue&type=style&index=1&id=64f15792&prod&rel=stylesheet%2Fscss&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f15792",
  null
  
)

export default component.exports