<template>
  <div class="app-container">
    <div class="main-body">
      <el-row class="call-title">
        <el-col :span="24">
          <i class="lc-tms-iconyunweiguanli icon-cls icon-cls-green" />
          <span class="title">消纳场信息</span>
        </el-col>
      </el-row>
      <div ref="searchBar" class="form-cls">
        <el-form @heightChange="_getTableHeight()" size="small">
          <el-row>
            <el-col :span="6">
              <el-form-item label="经营单位:">
                <el-input
                  v-model.trim="queryParams.businessUnit"
                  clearable
                  type="text"
                  class="input-cls input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                />
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item>
                <el-button plain size="small" type="primary" icon="el-icon-search" @click="queryList"> 查询 </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- </search-bar> -->
      </div>
      <div class="main-body">
        <vxe-toolbar ref="xToolbar" style="margin-left: 10px; padding-right: 10px">
          <template #buttons>
            <el-upload
              :action="`${uploadUrl}?type=0`"
              :data="uploadData"
               :headers="uploadHeaders"
              :on-success="uploadFileSuccess"
              :on-error="uploadFileError"
              :show-file-list="false"
              :file-list="[]"
            >
              <el-button plain key="addFile" size="small" type="primary"> 新增导入 </el-button>
            </el-upload>

            <el-upload
              style="margin-left: 10px"
              :data="uploadData"
               :headers="uploadHeaders"
              :on-success="uploadFileSuccess"
              :on-error="uploadFileError"
              :action="`${uploadUrl}?type=1`"
              :show-file-list="false"
              :file-list="[]"
            >
              <el-button plain key="addUpdate" size="small" type="primary"> 导入更新 </el-button>
            </el-upload>
            <el-button plain style="margin-left: 10px" key="addFile1" size="small" @click="exportFileTemp" type="primary">下载导入模板</el-button>
            <el-button plain  style="margin-left: 10px" key="addFile" size="small" @click="exportFile" type="primary"> 导出 </el-button>
          </template>
          <template #tools>
            <vxe-pager
              align="left"
              placement="top"
              :total="total"
              :current-page.sync="queryParams.currentPage"
              border
              :page-sizes="[100, 120, 150]"
              size="small"
              :page-size.sync="queryParams.pageSize"
              :layouts="['PrevJump', 'PrevPage', 'Jump', 'NextPage', 'NextJump', 'Sizes', 'Total']"
              @page-change="queryList('0')"
            />
          </template>
        </vxe-toolbar>
      </div>
      <el-row class="form-cls button-cls">
        <el-col :span="24">
          <vxe-table
            id="dataListTable"
            ref="table"
            v-loading="listLoading"
            class="-column-demo"
            :export-config="{}"
            :data="dataList"
            fit
            :height="_tableHeight"
            element-loading-text="加载中"
            border
            stripe
            column-key
            resizable
            highlight-current-row
            :resizable-config="{ minWidth: 50 }"
            :custom-config="{ storage: true }"
            :scroll-y="{ gt: -1 }"
            show-overflow="tooltip"
            show-footer-overflow="tooltip"
            show-header-overflow="tooltip"
            tooltip-effect="dark"
            size="small"
            style="width: 100%"
          >
            <vxe-table-column type="seq" title="序号" width="50" align="center" fixed="left" />
            <vxe-table-column field="businessUnit" title="经营单位" align="center" width="200" />
            <vxe-table-column field="contact" title="单位联系人" align="center" width="200" />
            <vxe-table-column field="phone" title="联系电话" align="center" width="250" />
            <vxe-table-column field="address" title="处置地址" align="center" width="250" />
            <vxe-table-column field="street" title="属地街道" align="center" width="200" />
            <vxe-table-column field="longitude" title="经度" align="center" width="200" />
            <vxe-table-column field="latitude" title="纬度" align="center" width="200" />
            <vxe-table-column field="idNumber" title="证件编号" align="center" width="200" />
            <vxe-table-column field="statusText" title="证件状态" align="center" width="200" />
            <vxe-table-column field="type" title="处置类型" align="center" width="200" />
            <vxe-table-column field="capacity" title="场地容量" align="center" width="200" />
            <vxe-table-column field="startTime" title="开始日期" align="center" width="200" />
            <vxe-table-column field="endTime" title="结束日期" align="center" width="200" />
            <vxe-table-column align="center" fixed="right" title="操作" width="120">
              <template v-slot="{ row }">
                <span style="color: deepskyblue; cursor: pointer" @click="handleWeilan(row)">围栏标注</span>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-col>
      </el-row>
      <!-- 围栏 -->
      <el-dialog :visible.sync="weilanVisible" :before-close="weilanClose" title="围栏标注" width="1290px" :close-on-click-modal="false">
        <el-row :gutter="24">
          <el-col :span="16">
            <!-- <div class="input-item">
              <div class="input-item-prepend">
                <span class="input-item-text" style="width:8rem;">请输入关键字</span>
              </div>
              <input id='tipinput' type="text">
            </div> -->
            <div class="amap-section">
              <el-amap
                :plugin="plugin"
                :center="center"
                :amap-manager="amapManager"
                :zoom="zoom"
                :events="events"
                :resize-enable="true"
                class="map"
              ></el-amap>
            </div>
          </el-col>
          <el-col :span="8">
            <el-form :model="formData" :rules="formRules" ref="formData" label-width="92px">
              <el-form-item label="围栏名称：" prop="name">
                <el-input v-model="formData.name" size="small" class="input-width-16" />
              </el-form-item>
              <el-form-item label="触发类型：" prop="driveType">
                <el-checkbox-group v-model="formData.driveType">
                  <el-checkbox :label="1" name="driveType">驶入围栏</el-checkbox>
                  <el-checkbox :label="2" name="driveType">驶出围栏</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="中心经度："  prop="lon">
                <el-input v-model="formData.longitude" size="small" class="input-width-16" @change="weilanDataUpdate" />
              </el-form-item>
              <el-form-item label="中心纬度：" prop="organizationName">
                <el-input v-model="formData.latitude" size="small" class="input-width-16" @change="weilanDataUpdate" />
              </el-form-item>
              <el-form-item label="围栏半径：" prop="radius">
                <el-input v-model="formData.radius" size="small" class="input-width-16" @change="weilanDataUpdate">
                  <template slot="append">米</template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitData">保存</el-button>
                <el-button @click="weilanVisible = false">取 消</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { AMapManager } from 'vue-amap'
import CalTableHeight from '@/mixins/calTableHeight'
import { baseCollectList as queryList, baseCollectListExport as queryListExport,  getElectricFence, getElectricFenceEdit, 
  contactList, contactListSave, contactListAdd, contactListDel, contactListEdit,
  baseCollectDownloadTemplate as downloadTemplate
} from '@/api/base'
import { getToken } from '@/utils/auth.js'
import downloadFile  from '@/utils/downloadFile'
const amapManager = new AMapManager()
let circleMap = null
let markerMap = null
export default {
  data() {
    return {
      total: 0,
      uploadUrl: '/admin-gov/recycleProject/upload', // admin-gov
      uploadData: {},
      uploadHeaders:{
        Authorization: `Bearer ${getToken()}` // 让每个请求携带自定义token 请根据实际情况自行修改
      },
      queryParams: {
        businessUnit: '',
        currentPage: 1,
        pageSize: 100
      },
      dataList: [],
      listLoading: false,
      weilanVisible: false,
      // 表单
      formData: {
        dataId: '',
        name: '',
        driveType: [],
        radius: 100,
        latitude: '',
        longitude: ''
      },
      center: [120.236713, 30.206855],
      formRules: {
        name: [{ required: true, message: '请输入围栏名称', trigger: 'blur' }],
        type: [{ required: true, message: '请选择触发类型', trigger: 'blur' }],
        radius: [{ required: true, message: '请输入围栏半径', trigger: 'blur' }]
      },
      // 地图
      map: null,
      plugin: [
        // 'AMap.OverView', // 地图鹰眼插件
        // 'AMap.ToolBar', //  地图工具条
        // 'AMap.MapType', //  类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制,
        'AMap.Autocomplete',
        'AMap.PlaceSearch',
      ],
      resizeEnable: false, //是否监控地图容器尺寸变化
      zoom: 16, // 设置初始化级别
      mapStyle: 'amap://styles/blue', // 使用的自
      amapManager,
      events: {
        init: map => {
          this.map = map
          console.log('map init ok', map)
        }
      }
    }
  },
  mixins: [CalTableHeight],
  async mounted() {
    this.$nextTick(() => {
      // 手动将表格和工具栏进行关联,否则列配置将失效
      // this.$refs.table.connect(this.$refs.xToolbar)
    })
    this.$nextTick(() => {
      this._getTableHeight()
    })
    this.queryList()

    setTimeout(() => {

      // this.startTrack()
      //输入提示
      // var auto = new AMap.Autocomplete({
      //     input: "tipinput"
      // });
      // var placeSearch = new AMap.PlaceSearch({
      //     map: this.map
      // });  //构造地点查询类
      // AMap.event.addListener(auto, "select", select);//注册监听，当选中某条记录时会触发
      // function select(e) {
      //     placeSearch.setCity(e.poi.adcode);
      //     placeSearch.search(e.poi.name);  //关键字查询查询
      // }
    }, 50);
  },
  methods: {
    uploadFileSuccess() {
      this.$message.success(`上传成功`)
    },
    uploadFileError(err) {
      const res = JSON.parse(err.message)
      this.$message.error( res.message || `上传失败`)
    },
    exportFile() {
      const { queryParams } = this
      queryListExport({
        ...queryParams
      }).then(res => {
        downloadFile(res)
      })
    },
    exportFileTemp(){
      return downloadTemplate({
      }).then(res=>{
        downloadFile(res)
      })
    },
    queryList() {
      this.listLoading = true
      return queryList(this.queryParams)
        .then(({ data }) => {
          this.dataList = data.records.map((ele)=>{
            return {
              ...ele,
              statusText: ['生效中', '已失效'][ele.status] || '异常'
            }
          })
          this.total = data.total
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    handleWeilan(row) {
      return getElectricFence({type: 1, dataId: row.id}).then(({data}) => {
        const _data = data[0] || {}
        if(!_data.latitude && !_data.longitude) {
          this.$message.error('未获取到经纬度数据')
        }
        this.formData = {
          ...this.formData,
          ..._data,
          name:_data.name,
          dataId: row.id,
          radius: _data.r,
          latitude: _data.latitude || this.center[0],
          longitude: _data.longitude || this.center[1],
          driveType: [],
          // driveIn: data.driveIn, // 驶入告警 0-不告警 1-告警
          // driveOut: data.driveOut // 驶出告警 0-不告警 1-告警
        }
        if(_data.driveIn === 1) { 
          this.formData.driveType.push(1) // 驶入告警
        }
        if(_data.driveOut === 1) {
          this.formData.driveType.push(2) // 驶出告警
        }
        this.weilanVisible = true
        setTimeout(() => {
          this.startTrack()
        })
      })  
    },
    submitData() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: '正在保存...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.6)',
            target: document.getElementById('el-dialog')
          })
          const { driveType = [] } = this.formData
          const driveIn = driveType.includes(1) ? 1 :0
          const driveOut = driveType.includes(2) ? 1 :0
          const { radius, latitude, longitude} = this.formData
          getElectricFenceEdit({
            ...this.formData,
            driveIn,
            driveOut,
            r: parseFloat(radius),
            driveType: undefined,
            radius: undefined,
          }).then(res => {
            if (res.code === 200) {
              this.weilanVisible = false
              this.$message.success('保存成功')
              this.resetForm()
              this.queryList()
            } else {
              this.$message.error(res.msg)
            }
          }).finally(()=>{
            loading.close()
          })
        }
      })
      // setTimeout(() => {
      //   this.startTrack()
      // })
    },
    weilanDataUpdate(){
        // 更新地图
        // const latitude = '120.236713'
        // const longitude = '30.206855'
        const { radius, latitude, longitude} = this.formData
        circleMap.setRadius(radius)
        circleMap.setCenter(
          new AMap.LngLat( longitude,latitude),
        )
        markerMap.setPosition( 
          new AMap.LngLat( longitude, latitude ),
        )

        this.map.setCenter( 
          new AMap.LngLat( longitude, latitude ),
        )
    },
    submitContacts(){
      console.log('contactsDataList', this.contactsDataList)
      this.contactListSave()
    },
    weilanClose(done ){
      console.log('weilanClose')
      this.map.remove(markerMap)
      this.map.remove(circleMap)
      done()
    },
    startTrack() {
      const map = amapManager.getMap()
      if(!this.map){
      this.map = map
      }
      if(markerMap) {
        map.remove(circleMap)
      }
      if(circleMap) {
        map.remove(circleMap)
      }

      const { latitude:  Lat, longitude: Lng, radius = 0 } = this.formData
      const marker = new AMap.Marker({
        map: map,
        icon: new AMap.Icon({
          size: new AMap.Size(25, 34),
          image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
          imageSize: new AMap.Size(135, 40),
          imageOffset: new AMap.Pixel(-95, -3)
        }),
        position: new AMap.LngLat(Lng, Lat),
        // position: ['120.236713', '31.206855'],
        offset: new AMap.Pixel(-13, -30)
      })
      const circle = new AMap.Circle({
        center: new AMap.LngLat(Lng, Lat),
        radius, //半径
        borderWeight: 0,
        fillOpacity: 0.4,
        fillColor: '#1791fc',
        strokeWeight: 0
      })
      map.setCenter( 
        new AMap.LngLat( Lng, Lat ),
      )
      circleMap = circle
      markerMap = marker
      map.add([circle, marker])
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.input-cls {
  width: 260px;
  font-size: 14px;
  // feat: 搜索栏过多收起
  margin-right: 10px;
}

.icon-cls-green {
  color: #67c23a;
}

.dialog-row {
  margin-top: 10px;
}
</style>

<style rel="stylesheet/scss" lang="scss">
.amap-section {
  height: 600px;
}

.validFieldClass .el-input__inner {
  border-color: #f56c6c;
}

.el-pagination {
  float: right;
}

.-column-demo .vxe-header--row .vxe-header--column.-ghost,
.-column-demo .vxe-header--row .vxe-header--column.-chosen {
  background-color: #dfecfb;
}

.vxe-table--footer .col-blue {
  background: #f2f5f0;
}

/*单号复制样式 开始*/
.copy-content {
  right: 0.1em;
  top: 0.1em;
  float: right !important;
  position: absolute !important;
}

.copy-content:hover {
  background-color: #1482f0;
  float: right !important;
  height: 1.8em;
  width: 1.8em;
}

.write-color {
  border-color: #f0f0f0;
}

/*单号复制样式 结束*/
.input-width-6 {
  width: 60% !important;
}

.input-width-7 {
  width: 70% !important;
}

.input-width-8 {
  width: 80% !important;
}

.input-width-16 {
  width: 300px;
}
</style>
